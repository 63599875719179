import './App.css';
import React, { useState } from 'react';
import styled from 'styled-components';

// Main function
function App() {
  const [selectedTab, setSelectedTab] = useState('HOME');

  const setContent = (type) => {
    console.log('Changing content to ' + type);
    setSelectedTab(type);
  };

  const MainContent = () => {
    switch (selectedTab) {
      case 'HOME':
        return <Home />;
      case 'SPORT':
        return <Sport />;
      case 'POLITICS':
        return <Politics />;
      case 'ENTERTAINMENT':
        return <Entertainment />;
      case 'TECH':
        return <Tech />;
      default:
        return <p>ERROR: Content failed to load</p>;
    }
  };

  const Tab = styled.button`
    font-size: 14px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    background: white;
    border: 0;
    outline: 0;
    align-items: center;

    ${({ active }) =>
      active &&
      `
      border-bottom: 2px solid grey;
      opacity: 1;
    `}
  `;

  const ButtonGroup = styled.div`
    display: block;
  `;

  const types = ['HOME', 'SPORT', 'POLITICS', 'ENTERTAINMENT', 'TECH'];

  const TabGroup = () => {
    const [active, setActive] = useState(types[0]);

    const handleTabClick = (type) => {
      setActive(type);
      setContent(type);
    };

    return (
      <>
        <ButtonGroup>
          {types.map((type) => (
            <Tab
              key={type}
              active={active === type}
              onClick={() => handleTabClick(type)}
            >
              {type}
            </Tab>
          ))}
        </ButtonGroup>
      </>
    );
  };

  return (
    <div className="App">
      <div className="Header">
        <h1>THATS FAX</h1>

        <TabGroup />
      </div>
      <MainContent />
    </div>
  );
}

function Home() {
  return (
    <p>Home</p>
  
  );
}

function Sport() {
  return (
    <p>Sport</p>
  
  );
}

function Politics() {
  return (
    <p>Politics</p>
  
  );
}

function Entertainment() {
  return (
    <p>Entertainment</p>
  
  );
}

function Tech() {
  return (
    <p>Tech</p>
  
  );
}

export default App;
